import * as Sentry from '@sentry/nuxt';
import { useRuntimeConfig } from '#imports';

const { public: { sentry } } = useRuntimeConfig();
Sentry.init({
  dsn: sentry.dsn,
  environment: sentry.environment,
  release: sentry.release,
  tracesSampleRate: 1.0,
});
